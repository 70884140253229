import * as React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import Seo from '../components/seo'

const Wrapper = styled.div`
  color: white;

  padding: 32px 0;

  h1 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1em;
    text-decoration: underline;
    text-decoration-color: var(--red);
  }

  div {
    text-align: center;
    max-width: var(--site-width);
    margin: 3em auto 4em auto;
    p {
      text-align: center;
      font-weight: bold;

      @media (min-width: 768px) {
        font-size: 3rem;
      }
    }

    ul,
    ul li {
      margin-left: 0;
      padding-left: 0;
    }
    ul a {
      color: var(--blue);
      text-decoration-color: var(--red);
    }
  }
`

const SitemapPage = () => (
  <Layout>
    <Seo
      title="Sitemap for Destination Inn"
      description="sitemap page for destionation in"
      canonical="https://destinationinn.ca/sitemap/"
    />

    <Wrapper>
      <div>
        <h1>Sitemap for Destination Inn</h1>
        <ul>
          <li>
            <Link to="/" title="destination inn homepage">
              Home
            </Link>
          </li>
          <li>
            <Link to="/gallery/" title="our gallery">
              Our Gallery
            </Link>
          </li>
          <li>
            <Link to="/contact/" title="contact destination inn">
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="/links/"
              title="popular activities in and around radium hotpsrings"
            >
              Things to do
            </Link>
          </li>
          <li>
            <Link
              to="/careers/"
              title="Careers and Opporutinesa at Destination Inn"
            >
              Careers
            </Link>
          </li>
          <li>
            <Link
              to="/search-by-availability/"
              title="book a room by availability"
            >
              Book by availability
            </Link>
          </li>
          <li>
            <Link to="/search-by-calendar/" title="book a room by calendar">
              Book by calendar
            </Link>
          </li>
        </ul>
      </div>
    </Wrapper>
  </Layout>
)

export default SitemapPage
